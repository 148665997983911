<template>
  <div class="bt-home pb-5 mb-5">
    <div class="d-flex backCustomSatu" style="height: 230px; border-radius: 0 0 30px 30px"></div>
    <div class="mx-5" style="margin-top: -220px">
      <div class="d-flex justify-center align-center mb-5">
        <div class="credit-card">
          <div class="card-logo">Cash Inflow</div>
          <div class="chip">
            <img src="../../assets/logo.png" alt="" />
          </div>
          <div v-if="loading" class="card-warning">Sedang memuat data...</div>
          <div v-else>
            <div v-if="isOnline" class="card-number">Rp. {{ formatRupiah(totalNominal) }}</div>
            <div v-else class="card-warning d-flex justify-center align-center" style="color: darkred"><v-icon small class="mr-2" style="color: darkred">mdi-wifi-strength-4-alert</v-icon>Diperlukan Koneksi Internet...</div>
          </div>
          <div class="card-details">
            <div>
              <div class="card-holder" style="font-size: 8px">{{ userRole.role_name }} | {{ user.branch_name }}</div>
              <div class="card-holder text-caption" style="font-size: 12px; text-transform: uppercase">{{ user.nama_user }}</div>
            </div>
            <div class="expiry d-flex align-center text-caption">{{ getDate() }}</div>
          </div>
        </div>
      </div>
      <div style="margin-top: 40px">
        <v-row no-gutters>
          <v-col cols="6">
            <router-link to="/penagihan/form_penagihan">
              <v-sheet class="pa-3 mr-1 rounded-pill d-flex justify-center align-center" style="background-color: #672178">
                <div>
                  <v-icon dense class="pa-2 rounded-pill mr-2" style="background-color: white; color: #672178"> mdi-hand-coin </v-icon>
                  <span class="text-caption text-center white--text text--lighten-5 mt-1"> Penagihan </span>
                </div>
              </v-sheet>
            </router-link>
          </v-col>
          <v-col cols="6">
            <router-link to="/penagihan/laporan_penagihan">
              <v-sheet class="pa-3 ml-1 rounded-pill d-flex justify-center align-center" style="background-color: #672178">
                <div>
                  <v-icon dense class="pa-2 rounded-pill mr-2" style="background-color: white; color: #672178"> mdi-chart-timeline-variant-shimmer </v-icon>
                  <span class="text-caption text-center white--text text--lighten-5 mt-1"> Laporan </span>
                </div>
              </v-sheet>
            </router-link>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="mt-4 mx-5">
      <v-card
        class="pa-3 rounded-lg"
        :style="{
          backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg-lhp)' : 'var(--light-bg-lhp)',
        }"
      >
        <!-- <v-col cols="12">
          <v-autocomplete dense :color="$vuetify.theme.dark ? 'color: white' : 'color: black'" autocomplete="off" :items="list.branch" label="Filter Cabang" />
        </v-col> -->
        <v-row no-gutters>
          <v-col cols="6">
            <v-sheet class="pa-2 ma-2 rounded-lg" style="background-color: transparent; border: 1px solid darkgray">
              <h5 class="text-caption">Menagih</h5>
              <span class="text-subtitle-1 text-center font-weight-bold">{{ menagih }}</span>
            </v-sheet>
          </v-col>
          <v-col cols="6">
            <v-sheet class="pa-2 ma-2 rounded-lg" style="background-color: transparent; border: 1px solid darkgray">
              <h5 class="text-caption">Tertagih</h5>
              <span class="text-subtitle-1 text-center font-weight-bold">{{ tertagih }}</span>
            </v-sheet>
          </v-col>
          <v-col cols="6">
            <v-sheet class="pa-2 ma-2 rounded-lg" style="background-color: transparent; border: 1px solid darkgray">
              <h5 class="text-caption">Total Target</h5>
              <span class="text-subtitle-1 text-center font-weight-bold">Rp. {{ formatRupiah(target) }}</span>
            </v-sheet>
          </v-col>
          <v-col cols="6">
            <v-sheet class="pa-2 ma-2 rounded-lg" style="background-color: transparent; border: 1px solid darkgray">
              <h5 class="text-caption">Total Realisasi</h5>
              <span class="text-subtitle-1 text-center font-weight-bold">Rp. {{ formatRupiah(realisasi) }}</span>
            </v-sheet>
          </v-col>
          <p class="pa-2 d-flex align-center text-caption font-italic text-grey-darken-1 blue--text">
            <v-icon small class="mr-1 blue--text">mdi-information-variant-circle</v-icon>
            Data yang ditampilkan merupakan rekapitulasi cabang {{ user.branch_name }}
          </p>
        </v-row>
      </v-card>
    </div>
    <!-- GRAFIK CABANG -->
    <ChartCabang />
    <!-- END GRAFIK CABANG -->

    <!-- GRAFIK BULANAN -->
    <ChartBulanan />
    <!-- END GRAFIK BULANAN -->

    <!-- GRAFIK PETUGAS -->
    <ChartPetugas />
    <!-- END GRAFIK PETUGAS -->

    <!-- CARAOUSEL IMAGE -->
    <div class="mt-3 mx-5">
      <v-card
        class="pa-3 rounded-lg"
        :style="{
          backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg-lhp)' : 'var(--light-bg-lhp)',
        }"
      >
        <v-row>
          <v-col cols="12" class="d-flex">
            <div class="d-flex justify-start">
              <span class="text-subtitle-1 font-weight-bold align-center"> Riwayat Terbaru </span>
            </div>
          </v-col>
        </v-row>
        <v-divider class="mx-3 my-2" />
        <v-row>
          <v-col cols="12">
            <div class="pa-3">
              <!-- Carousel Gambar -->
              <v-carousel class="rounded-lg" :show-arrows="false" hide-delimiters cycle height="300">
                <v-carousel-item v-for="(item, index) in chartRiwayat" :key="index">
                  <router-link :to="`/penagihan/detail/${item.id}`" class="image-wrapper">
                    <v-img :src="item.image" cover height="300"></v-img>
                  </router-link>
                  <div class="text-container">
                    <h6 class="text-subtitle-1 font-weight-bold">
                      {{ truncateText(item.title, 20) }}
                    </h6>
                    <span class="text-caption">{{ truncateText(item.title, 50) }}</span>
                  </div>
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <!-- END CAROUSEL IMAGE -->
  </div>
</template>

<script>
import Toast from "@/components/Toast";
import services from "@/services";
import { mapGetters } from "vuex";
import { ChartRiwayatService } from "@/services/chartRiwayat";
import helper from "@/utils/helper";
import BaseChart from "@/components/BaseChart.vue";
import ChartCabang from "@/components/Chart/ChartCabang.vue";
import ChartBulanan from "@/components/Chart/ChartBulanan.vue";
import ChartPetugas from "@/components/Chart/ChartPetugas.vue";
import { getData, deleteData, saveData } from "@/utils/indexedDB";
export default {
  name: "PenagihanDashboard",
  components: {
    Toast,
    BaseChart,
    ChartCabang,
    ChartBulanan,
    ChartPetugas,
  },
  data() {
    return {
      list: {
        branch: [],
      },
      rembug: [],
      menagih: 0,
      tertagih: 0,
      target: 0,
      realisasi: 0,
      currentIndex: 0,
      chartRiwayat: [],
      isOnline: navigator.onLine,
      cashInFlow: [],
      totalNominal: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user", "userRole"]),
  },

  async created() {
    this.chartRiwayat = await ChartRiwayatService.getAll();
  },
  mounted() {
    this.getInfoPenagihan();
    this.cashInFlowToday();
    this.onlineHandler = async () => {
      this.updateNetworkStatus();
    };

    window.addEventListener("online", this.onlineHandler);
    window.addEventListener("offline", this.updateNetworkStatus);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.onlineHandler);
    window.removeEventListener("offline", this.updateNetworkStatus);
  },
  methods: {
    ...helper,
    async cashInFlowToday() {
      if (this.isOnline) {
        if (!this.user || !this.user.branch_code || !this.userRole) {
          console.error("User data tidak lengkap.");
          return;
        }

        this.loading = true;
        let payload = new FormData();
        payload.append("branch_code", this.user.branch_code);

        try {
          const req = await services.listPenagihan(payload, this.user.token);
          const response = req.data;

          if (response.status === true && Array.isArray(response.data)) {
            this.cashInFlow = response.data.filter((item) => item.fa_code === this.user.fa_code);

            this.totalNominal = this.cashInFlow.reduce((sum, item) => {
              return sum + (parseFloat(item.amount) || 0);
            }, 0);
          }

          this.loading = false;
        } catch (error) {
          console.error("Gagal mengambil data:", error);
          this.$store.dispatch("showAlert", { show: false });

          setTimeout(() => {
            this.$store.dispatch("showAlert", {
              show: true,
              msg: "Gagal mengambil data. Coba lagi nanti.",
              color: "error",
              position: "top",
            });
          }, 3000);

          this.loading = false;
        }
      }
    },

    async getInfoPenagihan() {
      if (this.isOnline) {
        let payload = new FormData();
        payload.append("branch_code", this.user.branch_code);
        try {
          const req = await services.infoPenagihan(payload, this.user.token);
          const response = req.data;
          if (response.status === true && response.data !== null) {
            this.menagih = response.data.menagih;
            this.tertagih = response.data.tertagih;
            this.target = response.data.target;
            this.realisasi = response.data.realisasi;

            await deleteData("info_penagihan", "info_penagihan");
            await saveData("info_penagihan", response, "info_penagihan");
          }
        } catch (error) {
          this.$store.dispatch("showAlert", { show: false });
          setTimeout(() => {
            this.$store.dispatch("showAlert", {
              show: true,
              msg: error,
              color: "error",
              position: "top",
            });
          }, 3000);
        }
      } else {
        const infoPenagihan = await getData("info_penagihan", "info_penagihan");
        const req = infoPenagihan?.data?.data;
        if (req?.status === true && req?.data !== null) {
          this.menagih = req.data.menagih;
          this.tertagih = req.data.tertagih;
          this.target = req.data.target;
          this.realisasi = req.data.realisasi;
        }
      }
    },

    getDate() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const year = today.getFullYear();
      const shortYear = year.toString().slice(-2);
      const month = String(today.getMonth() + 1).padStart(2, "0");
      return `${day}/${month}/${shortYear}`;
    },
    formatRupiah(value) {
      return new Intl.NumberFormat("id-ID").format(value);
    },

    updateNetworkStatus() {
      this.isOnline = navigator.onLine;
    },
  },
};
</script>

<style scoped>
.text-container {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 12px;
  text-align: center;
}

.credit-card {
  width: 100%;
  height: 200px;
  border-radius: 15px;
  padding: 20px;
  color: white;
  background-size: 300% 300%;
  background: radial-gradient(ellipse farthest-corner at right bottom, #fedb37 0%, #fdb931 8%, #9f7928 30%, #8a6e2f 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #ffffac 8%, #d1b464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  animation: gradientMove 6s linear infinite;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.2));
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}

.credit-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: url("../../images/Assetback.webp") repeat;
  background-size: 40px 40px;
  background-position: bottom right;
  opacity: 0.2;
  z-index: -1;
  /* Efek fade-out melengkung */
  -webkit-mask-image: radial-gradient(circle at center, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0) 90%);
  mask-image: radial-gradient(circle at center, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0) 90%);
}

.chip img {
  width: 40px;
}

.card-number {
  font-size: 24px;
  letter-spacing: 2px;
  text-align: center;
  font-weight: bold;
  margin-top: -30px;
}
.card-warning {
  font-size: 12px;
  text-align: center;
  margin-top: -30px;
  font-weight: bold;
}

.card-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.card-logo {
  position: absolute;
  top: 27px;
  right: 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 0.8;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 75%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 25%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>

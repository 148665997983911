<template>
  <v-container>
    <v-card>
      <slot name="title"></slot>
      <div style="overflow-x: auto; width: 100%">
        <div style="min-width: 300px; height: 300px; position: relative">
          <canvas ref="chartCanvas"></canvas>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    chartType: {
      type: String,
      default: "bar",
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: { autoSkip: false }, // Pastikan label tidak di-skip
          },
        },
      }),
    },
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const canvas = this.$refs.chartCanvas;
      const ctx = canvas.getContext("2d");

      // Atur lebar canvas berdasarkan jumlah data
      const dataLength = this.chartData.labels.length;
      const barWidth = 10; // Atur lebar tiap batang sesuai kebutuhan
      canvas.width = dataLength * barWidth; // Set lebar canvas dinamis

      new Chart(ctx, {
        type: this.chartType,
        data: this.chartData,
        options: this.chartOptions,
      });
    },
  },
};
</script>

<template>
  <div>
    <!-- GRAFIK BULANAN -->
    <div class="mt-3 mx-5">
      <v-card
        class="pa-3 rounded-lg"
        :style="{
          backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg-lhp)' : 'var(--light-bg-lhp)',
        }"
      >
        <v-row>
          <v-col cols="12">
            <div class="pa-3">
              <span class="text-subtitle-1 font-weight-bold align-center"> Grafik Bulanan </span>
            </div>
          </v-col>
        </v-row>
        <v-divider class="mx-3" />
        <div class="chart-container mx-3">
          <div class="chart-wrapper">
            <BaseChart :chart-type="'line'" :chart-data="chartBulanan" />
          </div>
        </div>
      </v-card>
    </div>
    <!-- END GRAFIK BULANAN -->
  </div>
</template>
<script>
import BaseChart from "@/components/BaseChart.vue";
export default {
  name: "ChartBulanan",
  components: {
    BaseChart,
  },
  data() {
    return {
      chartBulanan: {
        labels: ["Mei 2024", "Agustus 2024"],
        datasets: [
          {
            label: "Menagih",
            borderColor: "#520e63",
            backgroundColor: "transparent",
            data: [23, 132],
            fill: true,
          },
          {
            label: "Tertagih",
            borderColor: "#27445D",
            backgroundColor: "transparent",
            data: [3, 47],
            fill: true,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              autoSkip: false,
            },
          },
        },
      },
    };
  },
};
</script>
<style scoped>
.chart-container {
  overflow-x: auto;
}

.chart-wrapper {
  min-width: 600px;
}
</style>
